import { useEffect, useState } from 'react';
import { isElectronRuntime } from 'src/lib/utils/isInElectron';

/**
 * Use external target in electron.
 *
 * Returns appropriate target for external links when opened in electron.
 *
 * @returns target
 */
export const useExternalTargetInElectron = () => {
  const [linkTarget, setLinkTarget] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isElectronRuntime()) {
      setLinkTarget('blank');
    }
  }, []);

  return linkTarget;
};
