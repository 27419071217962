import { Box, Divider, AbsoluteCenter } from '@chakra-ui/react';
import {
  Banner,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@designSystem';
import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { MainContent } from 'src/components/mainContent';
import { definedIntlMessages } from 'src/config/constants/definedIntlMessages';
import InfoIcon from 'src/designSystem/icons/info.svg';
import ErrorIcon from 'src/designSystem/icons/error.svg';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from 'src/config/constants/auth0';
import { ampli } from 'src/lib/tracking';
import { isElectronRuntime } from 'src/lib/utils/isInElectron';
import { getSsoRedirectUri } from 'src/config/constants/sdk';
import { SignInError } from 'src/config/types/error';

export type SigninPageError = {
  /** The error title */
  title: string;
  /** The error description */
  description?: string;
};

export type SigninPageProps = {
  /** An error which might have happened during the sign in */
  signInError?: SignInError;
  /** On the signin form submission */
  onSubmit: SubmitHandler<FieldValues>;
  /** If the form is submitting */
  isSubmitting: boolean;
  /** Error object to render in banner */
  error?: SigninPageError;
  /** If the login flow is one-time password */
  isOneTimePasswordFlow?: boolean;
  /** If it should use a custom backend domain */
  backendDomain?: string;
};

export const SigninPage = ({
  signInError,
  onSubmit,
  isSubmitting,
  error,
  isOneTimePasswordFlow,
  backendDomain,
}: SigninPageProps): JSX.Element => {
  const intl = useIntl();
  const router = useRouter();
  const query = router?.query;
  const { loginWithRedirect } = useAuth0();
  const isElectron = isElectronRuntime();

  useEffect(() => {
    if (!router || !query?.token) return;

    router.push({
      pathname: '/signin/organizations',
      query,
    });
  }, [router, query]);

  const {
    setFocus,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Focus the email field on init
    setFocus('email');
  }, [setFocus, setValue]);

  const ssoUrlParams = new URLSearchParams({
    desktop: isElectron.toString(),
    ...(!!backendDomain && {
      'backend-domain': backendDomain,
    }),
  });

  const ssoRedirectUri = router.isReady
    ? `${getSsoRedirectUri(getConfig().redirectPath)}?${ssoUrlParams}`
    : '';

  const getBanner = () => {
    if (error) {
      return (
        <Banner
          role="alert"
          colorScheme="red"
          icon={<ErrorIcon />}
          title={error.title}
          description={error.description}
        />
      );
    }

    let errorMessages = null;
    switch (signInError) {
      case 'invalid_token':
        errorMessages = [
          intl.formatMessage(definedIntlMessages.invalidTokenTitle),
          intl.formatMessage(definedIntlMessages.invalidTokenDescription),
        ];
        break;
      case 'not_allowed':
        errorMessages = [
          intl.formatMessage(definedIntlMessages.notAllowedTitle),
          intl.formatMessage(definedIntlMessages.notAllowedDescription),
        ];
        break;
      case 'sso_error':
        const ssoMessage = (router.query['sso_message'] ?? '') as string;
        errorMessages = [
          intl.formatMessage(definedIntlMessages.ssoErrorTitle),
          intl.formatMessage(definedIntlMessages.ssoErrorDescription, {
            ssoMessage,
          }),
        ];
        break;
      case 'no_organization':
        errorMessages = [
          intl.formatMessage(definedIntlMessages.noOrganizationsTitle),
          intl.formatMessage(definedIntlMessages.noOrganizationsDescription),
        ];
        break;
      default:
        break;
    }

    if (errorMessages) {
      const [title, description] = errorMessages;
      return (
        <Banner
          role="alert"
          icon={<InfoIcon />}
          title={title}
          description={description}
          data-testid="invalidTokenText"
        />
      );
    }
  };

  return (
    <MainContent
      title={<FormattedMessage defaultMessage="Log in to Dixa" />}
      footer={
        <Text fontSize="sm" textAlign="center">
          <FormattedMessage
            {...definedIntlMessages.signinPrivacyAndTermsDescription}
            values={{
              linkToTermsAndCondition: (chunks) => (
                <a
                  href="https://dixa.com/legal/tos"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
              linkToPrivacyPolicy: (chunks) => (
                <a
                  href="https://dixa.com/legal/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Text>
      }
      metaTitle={intl.formatMessage(definedIntlMessages.pageSigninTitle)}
      metaDescription={intl.formatMessage(
        definedIntlMessages.defaultMetaDescription,
      )}
      lead={
        isOneTimePasswordFlow ? (
          <FormattedMessage defaultMessage="Enter your email and we’ll send a one-time code to log in." />
        ) : (
          <FormattedMessage defaultMessage="Enter your email and we’ll send a magic link to log in." />
        )
      }
      banner={getBanner()}
    >
      <VStack
        id="signin-form"
        as="form"
        align="stretch"
        spacing="4"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="on"
        aria-label="Sign in form"
        data-testid="signin-form"
        noValidate
      >
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>
            <FormattedMessage defaultMessage="Email" />
          </FormLabel>
          <Input
            type="email"
            autoComplete="email"
            disabled={isSubmitting}
            aria-required={true}
            {...register('email', {
              required: {
                value: true,
                message: intl.formatMessage(
                  definedIntlMessages.invalidEmailRequired,
                ),
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: intl.formatMessage(
                  definedIntlMessages.invalidEmailPattern,
                ),
              },
            })}
          />
          {/* Add a hidden input to trigger the browsers autofill feature */}
          <input type="password" autoComplete="password" hidden />
          <FormErrorMessage>
            {errors?.email?.message?.toString?.()}
          </FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          width="100%"
          isLoading={isSubmitting}
          colorScheme="purple"
        >
          <FormattedMessage defaultMessage="Sign in" />
        </Button>

        <Box position="relative" pt="8" pb="8">
          <Divider />

          <AbsoluteCenter bg="white" px="4" fontSize="sm" color="gray.500">
            <FormattedMessage defaultMessage="OR" />
          </AbsoluteCenter>
        </Box>

        <Button
          width="100%"
          colorScheme="purple"
          fontWeight="normal"
          variant="ghost"
          mt="-4"
          onClick={() => {
            ampli.signinWithSsoClicked({});
            if (isElectron) {
              window.open(
                `${getSsoRedirectUri('/signin/sso-app')}?${ssoUrlParams}`,
                '_blank',
              );
            } else {
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: ssoRedirectUri,
                },
              });
            }
          }}
        >
          <FormattedMessage defaultMessage="Sign in with SSO" />
        </Button>
      </VStack>
    </MainContent>
  );
};
