import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';
import { theme, ThemeProvider } from '@designSystem';
import Head from 'next/head';
import '../lib/tracking/init';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from 'src/config/constants/auth0';

type NextPageWithLayout = NextPage & {
  /** Layout component to use as parent for page component */
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  /** Component to be rendered on route */
  Component: NextPageWithLayout;
};

export default function MyApp({
  Component,
  pageProps,
  locale = process.env.NEXT_PUBLIC_LOCALE,
}: AppPropsWithLayout & { locale?: string }) {
  // getLayout wraps page in layout component if specified
  const getLayout = Component.getLayout ?? ((page) => page);
  const config = getConfig();

  return (
    <Auth0Provider domain={config.domain} clientId={config.clientId}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <IntlProvider
        locale={locale ?? 'en-US'}
        defaultLocale={'en-US'}
        messages={pageProps.messages}
      >
        <ThemeProvider theme={theme}>
          {getLayout(<Component {...pageProps} />)}
        </ThemeProvider>
      </IntlProvider>
    </Auth0Provider>
  );
}
