// This is a rewrite of https://github.com/cheton/is-electron/blob/master/index.js

import { isClientRuntime } from './isClientRuntime';

/**
 * is Electron runtime
 *
 * Returns true if the app is running inside an Electron application.
 *
 * @returns {boolean}
 */
export const isElectronRuntime = (): boolean => {
  // Electron renderer process
  if (
    isClientRuntime() &&
    typeof window.process === 'object' &&
    (window.process as NodeJS.Process & { type?: string }).type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to false
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
};
