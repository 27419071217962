import { dixaRestApiEndpoints } from '../../config/constants/api';

export const getDixaRestAPIUrl = (
  options: {
    version?: number;
    backendDomain?: string;
  } = {},
) => {
  const { version = 1, backendDomain } = options;

  if (backendDomain) {
    return `${dixaRestApiEndpoints.protocol}://${dixaRestApiEndpoints.subdomain}.${backendDomain}/v${version}`;
  }

  return `${dixaRestApiEndpoints.protocol}://${dixaRestApiEndpoints.subdomain}.${dixaRestApiEndpoints.domain}/v${version}`;
};
