import { isClientRuntime } from 'src/lib/utils/isClientRuntime';

// This expects accounts.dixa.io to be a development domain,
//and in the future accounts.dixa.com to be production.
export const isDevelopmentDomain =
  isClientRuntime() && location.href.includes('accounts.dixa.io');

// Checks if the client is has a runtime and is hosted on localhost.
export const isLocalhost = () =>
  isClientRuntime() &&
  [
    'localhost',
    '0.0.0.0',
    '127.0.0.1',
    '[::1]',
    '0000:0000:0000:0000:0000:0000:0000:0001',
  ].some((domain) => location.href.includes(domain));

export const reCaptchaSiteKey = '6LfsUhgUAAAAANSkNIF8CjA_8RdKxrbT7IlIBZVQ';

// Either in staging accounts host or using staging backend API
export const isStagingEnv = (): boolean =>
  (isClientRuntime() &&
    (location.hostname.includes('.stag') ||
      new URLSearchParams(location.search)
        .get('backend-domain')
        ?.includes('euw1.stag'))) ??
  false;

export const getSsoRedirectUri = (redirectPath: string) => {
  if (location.hostname.includes('.stag')) {
    // stag environment uses branch name as a part of URI
    // include it in redirect URI
    const pathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname;
    return `${location.origin}${pathname}${redirectPath}`;
  } else {
    return `${location.origin}${redirectPath}`;
  }
};
