import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import {
  loadI18nMessages,
  MessageConfig,
} from 'src/lib/utils/loadI18nMessages';
import { isElectronRuntime } from '../../lib/utils/isInElectron';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  SigninPage as SigninPageComponent,
  SigninPageError,
} from '../../components/pages/signin/signinPage';
import { ampli } from '../../lib/tracking';
import { getDixaRestAPIUrl } from 'src/lib/utils/getDixaApiUrl';
import { useIntl } from 'react-intl';
import { definedIntlMessages } from 'src/config/constants/definedIntlMessages';
import { MainLayout } from 'src/components/mainLayout';
import { FieldValues } from 'react-hook-form';
import { customFetch, CustomFetchError } from 'src/lib/fetchers/customFetch';
import { dixaLoginWebsiteUrl } from 'src/config/constants/shared';
import { useMediaQuery } from '@chakra-ui/react';
import { SignInError } from 'src/config/types/error';

export function SigninPage(): JSX.Element {
  const router = useRouter();
  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<SigninPageError | undefined>();

  const {
    'backend-domain': backendDomain,
    error: signInError,
    otp,
  } = router?.query ?? {};

  const [isOpenedInPWA] = useMediaQuery(['(display-mode: minimal-ui)']);

  const shouldSendOneTimePassword = useMemo(() => {
    const isAndroid =
      typeof window !== 'undefined'
        ? /Android/i.test(navigator.userAgent)
        : false;

    return !isAndroid && !!(otp ?? isOpenedInPWA);
  }, [isOpenedInPWA, otp]);

  const sendMagicLink = useCallback(
    async ({ email }: { email: string }) => {
      // Get base url if it's eg. branch name
      const rootPath = window.location.pathname.split('/')[1];
      const rootPathIsCustomBaseHref = !['', 'signin'].includes(rootPath);

      const endpoint = `${getDixaRestAPIUrl({
        backendDomain: backendDomain as string,
      })}/login/delivery`;

      const isLocalhost =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';
      // Hardcode the location origin to a dixa website to be accepted by the api
      const origin = `${
        isLocalhost ? dixaLoginWebsiteUrl : window.location.origin
      }`;

      const returnUri = `${origin}/${
        rootPathIsCustomBaseHref ? rootPath + '/' : ''
      }signin/signin-verify?email=${email}${
        backendDomain ? '&backend-domain=' + backendDomain : ''
      }`;

      const payload = {
        send_to: email,
        delivery: isElectronRuntime() ? 'desktop' : 'email',
        return_uri: returnUri,
      };

      // Send request to Dixa API
      await customFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
    },
    [backendDomain],
  );

  const sendOneTimePassword = useCallback(
    async ({ email }: { email: string }) => {
      const endpoint = `${getDixaRestAPIUrl({
        backendDomain: backendDomain as string,
      })}/login/otp`;

      const payload = {
        email: email,
      };

      // Send request to Dixa API
      await customFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
    },
    [backendDomain],
  );

  const onSubmit = async (data: FieldValues): Promise<void> => {
    setIsSubmitting(true);
    setError(undefined);

    try {
      if (!shouldSendOneTimePassword) {
        await sendMagicLink({ email: data.email });

        // Redirect to magic link page
        router.push({
          pathname: '/signin/magiclink-sent',
          query: {
            email: data.email,
            ...(backendDomain ? { 'backend-domain': backendDomain } : {}),
          },
        });
      } else {
        await sendOneTimePassword({ email: data.email });

        // Redirect to one-time password page
        router.push({
          pathname: '/signin/one-time-code-sent',
          query: {
            email: data.email,
            ...(backendDomain ? { 'backend-domain': backendDomain } : {}),
            ...(otp ? { otp } : {}),
          },
        });
      }

      // Track that the user has successfully claimed a login
      ampli.signinMagiclinkFormSubmitted({
        email: data.email,
      });
    } catch (err) {
      const error = err as CustomFetchError;

      if (error.status === 500) {
        setError({
          title: intl.formatMessage(definedIntlMessages.networkError500title),
          description: intl.formatMessage(
            definedIntlMessages.networkError500Description,
          ),
        });
      } else {
        setError({
          title:
            error.info?.title ??
            `${intl.formatMessage(definedIntlMessages.networkError)}${
              error.status ? ':' + error.status : ''
            }`,
          description: error.statusText ?? error.message ?? '',
        });
      }

      setIsSubmitting(false);
    }
  };

  return (
    <SigninPageComponent
      signInError={signInError as SignInError}
      onSubmit={onSubmit}
      error={error}
      isSubmitting={!!isSubmitting}
      isOneTimePasswordFlow={shouldSendOneTimePassword}
      backendDomain={backendDomain as string}
    />
  );
}

export const getStaticProps: GetStaticProps = async (
  context,
): Promise<{ props: { messages: MessageConfig } }> => {
  return {
    props: {
      messages: await loadI18nMessages(context),
    },
  };
};

SigninPage.getLayout = (page: ReactElement): JSX.Element => {
  return <MainLayout>{page}</MainLayout>;
};

export default SigninPage;
