import { isLocalhost, isStagingEnv } from 'src/config/constants/sdk';

import { ampli } from '../tracking';

(() => {
  if (typeof window === 'undefined') return;

  ampli.load({
    environment: isLocalhost() || isStagingEnv() ? 'stag' : 'prod',
    client: {
      configuration: {
        defaultTracking: false,
      },
    },
  });
})();
