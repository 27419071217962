import { GetStaticProps } from 'next';
import { ReactElement } from 'react';
import { MainLayout } from 'src/components/mainLayout';
import {
  loadI18nMessages,
  MessageConfig,
} from 'src/lib/utils/loadI18nMessages';
import SigninPage from './signin';

export function IndexPage(): JSX.Element | null {
  return <SigninPage />;
}

export const getStaticProps: GetStaticProps = async (
  context,
): Promise<{ props: { messages: MessageConfig } }> => {
  return {
    props: {
      messages: await loadI18nMessages(context),
    },
  };
};

IndexPage.getLayout = (page: ReactElement): JSX.Element => {
  return <MainLayout>{page}</MainLayout>;
};

export default IndexPage;
