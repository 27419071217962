import { Box, Heading, Text, VStack } from '@designSystem';
import Head from 'next/head';
import { textConstants } from 'src/config/constants/text';
import { ChakraProps, forwardRef } from '@chakra-ui/react';
import DixaIcon from 'src/designSystem/icons/dixa.svg';
import { dixaWebsiteUrl } from 'src/config/constants/shared';
import { useExternalTargetInElectron } from 'src/hooks/useExternalTargetInElectron';

export type MainContentProps = ChakraProps & {
  /** The title used for page title in browser tabs */
  metaTitle: string;
  /** The description used for page description in search results */
  metaDescription?: string;
  /** The title of the page (rendered in <h1>) */
  title?: string | React.ReactNode;
  /** The lead text of the page (rendered in <p>) */
  lead?: string | React.ReactNode;
  /** A slot for eg. information banners */
  banner?: React.ReactNode;
  /** Children to appear as the main content */
  children?: string | React.ReactNode;
  /** Footer content */
  footer?: React.ReactNode;
  /** If it should show the dixa logo in main content */
  hasLogoOnSmall?: boolean;
  /** If the layout should be fixed as scrollable */
  hasScrollableContent?: boolean;
  /** If search robots should index the page */
  robotsNoIndex?: boolean;
};

export const MainContent = forwardRef<MainContentProps, 'div'>(
  (
    {
      metaTitle,
      metaDescription,
      title,
      lead,
      banner,
      children,
      footer,
      hasScrollableContent,
      robotsNoIndex,
      hasLogoOnSmall = true,
      ...rest
    }: MainContentProps,
    ref,
  ) => {
    const target = useExternalTargetInElectron();

    const containerProps = {
      width: '100%',
      maxWidth: '498px',
      paddingInline: 6,
    };

    const lineProps = {
      height: '1px',
      bg: 'gray.300',
    };

    const pageTitle = `${metaTitle} ${textConstants.siteTitleWithDivider}`;

    return (
      <>
        <Head>
          <title>{pageTitle}</title>
          <meta name="description" content={metaDescription} />
          {robotsNoIndex && <meta name="robots" content="noindex" />}
        </Head>
        <Box
          ref={ref}
          display="flex"
          alignItems="center"
          css={`
            ${hasScrollableContent ? 'height: 100vh; height: 100svh;' : ''}
          `}
          width="full"
          maxHeight={hasScrollableContent ? '800px' : 'none'}
          paddingBlockStart={20}
          paddingBlockEnd={hasScrollableContent ? 6 : undefined}
          {...rest}
        >
          <Box
            display="flex"
            flexDirection="column"
            maxHeight="100%"
            height={hasScrollableContent ? 'full' : 'auto'}
            width="full"
            alignItems="center"
          >
            <VStack
              {...containerProps}
              align="stretch"
              spacing={12}
              paddingBlockEnd={8}
              flexShrink={0}
            >
              {hasLogoOnSmall && (
                <Box display={['block', 'block', 'none']}>
                  <Box
                    as="a"
                    href={dixaWebsiteUrl}
                    target={target}
                    color="black"
                    display="inline-flex"
                    alignItems="center"
                    aria-label="Dixa link to sign in"
                    borderRadius="2px"
                  >
                    <DixaIcon width="40px" height="40px" />
                  </Box>
                </Box>
              )}

              {(title || lead) && (
                <Box>
                  {title && (
                    <Heading
                      as="h1"
                      size="lg"
                      paddingBlockEnd={2}
                      fontWeight="medium"
                    >
                      {title}
                    </Heading>
                  )}
                  {lead && (
                    <Text as="p" size="md" color="#444444">
                      {lead}
                    </Text>
                  )}
                </Box>
              )}
            </VStack>

            {banner && (
              <Box {...containerProps} paddingBottom={8}>
                {banner}
              </Box>
            )}

            {hasScrollableContent && (
              <Box {...containerProps}>
                <Box {...lineProps} />
              </Box>
            )}

            <Box
              overflowY={hasScrollableContent ? 'auto' : undefined}
              flexGrow={hasScrollableContent ? 1 : undefined}
              flexDirection="column"
              display="flex"
              alignItems="center"
              width="full"
            >
              <Box {...containerProps} paddingBlock={6}>
                {children}
              </Box>
            </Box>

            {hasScrollableContent && (
              <Box {...containerProps}>
                <Box {...lineProps} />
              </Box>
            )}

            {footer && (
              <Box
                {...containerProps}
                paddingBlockStart={hasScrollableContent ? 6 : 8}
                flexShrink={0}
              >
                {footer}
              </Box>
            )}

            {!hasScrollableContent && <Box width="100%" paddingBlockEnd={20} />}
          </Box>
        </Box>
      </>
    );
  },
);
