export const dixaRestApiEndpoints = {
  protocol: 'https',
  subdomain: 'api',
  domain: 'dixa.io',
  production: 'https://api.dixa.io',
};

export const apiConstants = {
  ipinfo: `https://ipinfo.io/?token=4948ba2d266f92`,
};
