import { defineMessages } from 'react-intl';

export const definedIntlMessages = defineMessages({
  invalidEmailProvider: {
    defaultMessage:
      '{emailProvider}  is not allowed, please use your work email.',
  },
  invalidEmailRequired: {
    defaultMessage: 'Please enter your email.',
  },
  invalidEmailPattern: {
    defaultMessage: 'Entered value does not match email format',
  },
  invalidPhoneNumberNotANumber: {
    defaultMessage:
      'Please enter a phone number starting with a country calling code with a leading + sign.',
  },
  invalidPhoneNumberTooShort: {
    defaultMessage: 'The phone number is too short.',
  },
  invalidPhoneNumberTooLong: {
    defaultMessage: 'The phone number is too long.',
  },
  invalidPhoneNumberInvalidCountry: {
    defaultMessage: 'The phone number has an invalid country code.',
  },
  invalidPhoneNumberInvalidLength: {
    defaultMessage: 'The phone number has an invalid length.',
  },
  invalidPhoneNumberRequired: {
    defaultMessage: 'Please enter your phone number.',
  },
  invalidSubdomainOrTaken: {
    defaultMessage: `The entered subdomain "{subdomain}" ({subdomain}.dixa.com) is already taken or unavailable.`,
  },
  invalidNameRequired: {
    defaultMessage: `Please enter your name.`,
  },
  invalidOrganizationRequired: {
    defaultMessage: `Please enter your organization name.`,
  },
  invalidGeneralTooShort: {
    defaultMessage: `Minimum {length} characters required.`,
  },
  invalidGeneralTooLong: {
    defaultMessage: `Maximum {length} characters allowed.`,
  },
  invalidTermsRequired: {
    defaultMessage: `You must accept the terms and conditions.`,
  },
  invalidSubdomainRequired: {
    defaultMessage: `Please enter a subdomain.`,
  },
  invalidOneTimePasswordCodeRequired: {
    defaultMessage: 'Please enter the code from your email.',
  },
  pageSigninTitle: {
    defaultMessage: 'Sign in',
  },
  pageSigninMagicLinkSentTitle: {
    defaultMessage: 'Check your email',
  },
  pageSigninMagicLinkVerifyTitle: {
    defaultMessage: 'Check your email',
  },
  pageSigninOneTimePasswordSentTitle: {
    defaultMessage: 'Check your email',
  },
  pageSigninSSOCallbackTitle: {
    defaultMessage: 'Signing in...',
  },
  pageSigninOrganizationsTitle: {
    defaultMessage: 'Choose organization',
  },
  pageSignupTitle: {
    defaultMessage: 'Sign up',
  },
  pageSignupCompletedTitle: {
    defaultMessage: 'Sign up completed',
  },
  defaultMetaDescription: {
    defaultMessage:
      'Turn every conversation into an opportunity for growth with Dixa, the customer service platform powered by technology and built for humans.',
  },
  networkError: {
    defaultMessage: 'Network error',
  },
  networkError500title: {
    defaultMessage: 'An unknown error occured',
  },
  networkError500Description: {
    defaultMessage: 'Error code: 500. Try again later.',
  },
  invalidTokenTitle: {
    defaultMessage: 'Expired or invalid token',
  },
  invalidTokenDescription: {
    defaultMessage: 'Your token is invalid or expired. Please login again.',
  },
  notAllowedTitle: {
    defaultMessage: 'Not allowed to sign in',
  },
  notAllowedDescription: {
    defaultMessage:
      'User does not have have permissions to sign in. It might be a result of a configuration change.',
  },
  noOrganizationsTitle: {
    defaultMessage: 'Not allowed to sign in',
  },
  noOrganizationsDescription: {
    defaultMessage: 'User does not have any associated organizations.',
  },
  ssoErrorTitle: {
    defaultMessage: 'SSO sign in failed',
  },
  ssoErrorDescription: {
    defaultMessage: 'An error happened during SSO sign in. {ssoMessage}',
  },
  invalidOneTimePasswordTitle: {
    defaultMessage: 'Expired or invalid one-time code',
  },
  invalidOneTimePasswordDescription: {
    defaultMessage:
      'The code entered was invalid or expired. Please login again.',
  },
  errorFetchingOrganizationsTitle: {
    defaultMessage: 'An error occurred',
  },
  errorFetchingOrganizations: {
    defaultMessage: 'Failed to load organizations, try again later.',
  },
  signupRequestInvalidTitle: {
    defaultMessage: 'Creating account failed',
  },
  signupRequestInvalidPhoneNumberDescription: {
    defaultMessage: 'Phone number is invalid',
  },
  signupRequestInvalidEmailDescription: {
    defaultMessage: 'Email is invalid',
  },
  signupRequestInvalidNameDescription: {
    defaultMessage: 'Name is invalid',
  },
  signupRequestInvalidDomainDescription: {
    defaultMessage: 'Domain is invalid',
  },
  signupRequestInvalidCompanyNameDescription: {
    defaultMessage: 'Organization name is invalid',
  },
  signupRequestInvalidDeniedDescription: {
    defaultMessage:
      "Your email domain has been used for spam previously and was blacklisted. This sometimes happens even for legitimate businesses. Please get in touch and we'll get you signed up immediately!",
  },
  signupRequestInvalidUnknownDescription: {
    defaultMessage: 'Unknown error. Please get in contact with us',
  },
  marketingConsentDescription: {
    defaultMessage:
      'I would like to receive occasional product updates, news, articles and info about upcoming events.',
  },
  termsConsentDescription: {
    defaultMessage:
      "I confirm that I have read and agree to Dixa's <linkToTermsAndCondition>Terms and services</linkToTermsAndCondition>, and that I have read and agree to Dixa's <linkToPrivacyPolicy>Privacy Policy</linkToPrivacyPolicy>.",
  },
  oneTimePasswordInvalidBannerTitle: {
    defaultMessage: 'A new one-time code has been sent to your email',
  },
  oneTimePasswordInvalidBannerDescription: {
    defaultMessage:
      'Your previous one-time code has been invalidated for security reasons. A new code has been sent to your email.',
  },
  oneTimePasswordInvalidDescription: {
    defaultMessage: 'Invalid One-Time Code',
  },
  signinPrivacyAndTermsDescription: {
    defaultMessage:
      "By signing in, you agree to Dixa's <linkToPrivacyPolicy>Privacy Policy</linkToPrivacyPolicy> and <linkToTermsAndCondition>Terms and Conditions</linkToTermsAndCondition>.",
  },
});
