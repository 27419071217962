import { isLocalhost, isStagingEnv } from './sdk';

let config: { domain: string; clientId: string; redirectPath: string } | null =
  null;

export const getConfig = () => {
  if (config) {
    return config;
  }

  const _config = {
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? '',
    clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? '',
    redirectPath: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_PATH ?? '',
  };

  if (isLocalhost() || isStagingEnv()) {
    _config.domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN_STAG ?? '';
    _config.clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_STAG ?? '';
  }

  if (!_config.domain || !_config.clientId || !_config.redirectPath) {
    throw new Error(`Auth0 config should be defined: ${_config}`);
  }

  config = _config;

  return config;
};
