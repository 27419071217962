export type CustomFetchError<T = Record<string, any>> = Error & {
  info?: T;
  status?: number;
  statusText?: string;
};

/**
 * Custom fetch function that can be used to fetch / post data from a remote source.
 * It default to send a GET request to the remote source with header application/json.
 */
export const customFetch = async (
  input: RequestInfo,
  init?: RequestInit,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const res = await fetch(input, {
    headers: { 'Content-Type': 'application/json', ...init?.headers },
    ...init,
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error: CustomFetchError = new Error(
      'An error occurred while fetching the data.',
    );
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    error.statusText = res.statusText;
    throw error;
  }

  return res.json();
};
